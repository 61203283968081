.slider :global(.swiper-wrapper) {
  margin-left: var(--container-margin);
  @apply items-stretch;
}

.slider :global(.swiper-slide) {
  @apply h-auto; /* needs to be auto to respect align-items: stretch in parent */
  @apply box-content;
  padding-right: var(--gutter-width);
}

/** Grid slider items will contain Teasers – Teasers should have a hover scale effect */
.slider :global(.swiper-slide) > * {
  @apply transform-gpu;
  @apply duration-150;
  @apply ease-in;
  @apply will-change-transform;
}
@media (prefers-reduced-motion: no-preference) {
  .slider :global(.swiper-slide) > * {
    @apply mouse:hover:scale-[1.05];
  }
}

/*
 * since :last-child could be something else (pagination or navigation),
 * and we don’t want to remember upating CSS when updating Markup order,
 * we rely on :last-of-type selector instead
 */
.slider :global(.swiper-slide:last-of-type) {
  /* we have to compensate the centering margin on the last slide,
  otherwise the slider won't fully go to the end of the grid
  and will just stop at the far right end of the page */
  padding-right: calc(var(--container-margin) * 2);
}

/**
 * Square (1x1) medium size grid item. Typically used for partner blocks.
 *
 * +------------+---------+
 * | Breakpoint | Columns |
 * +------------+---------+
 * | sm         | 3       |
 * | md         | 3       |
 * | lg         | 2       |
 * | xl         | 2       |
 * | 2xl        | 2       |
 * +------------+---------+
**/
.slider.square-medium :global(.swiper-slide) {
  width: calc((var(--column-plus-gutter-width) * 3) - var(--gutter-width));
}
@screen lg {
  .slider.square-medium :global(.swiper-slide) {
    width: calc((var(--column-plus-gutter-width) * 2) - var(--gutter-width));
  }
}

/**
 * Square (1x1) large size grid item. Typically used for album or artist cards.
 *
 * +------------+---------+
 * | Breakpoint | Columns |
 * +------------+---------+
 * | sm         | 3       |
 * | md         | 4       |
 * | lg         | 3       |
 * | xl         | 3       |
 * | 2xl        | 3       |
 * +------------+---------+
**/
.slider.square-large :global(.swiper-slide) {
  width: calc((var(--column-plus-gutter-width) * 3) - var(--gutter-width));
}
@screen md {
  .slider.square-large :global(.swiper-slide) {
    width: calc((var(--column-plus-gutter-width) * 4) - var(--gutter-width));
  }
}
@screen lg {
  .slider.square-large :global(.swiper-slide) {
    width: calc((var(--column-plus-gutter-width) * 3) - var(--gutter-width));
  }
}

/**
 * Rectangle (16x9) medium size grid item. Typically used for medium teasers.
 *
 * +------------+---------+
 * | Breakpoint | Columns |
 * +------------+---------+
 * | sm         | 5       |
 * | md         | 8       |
 * | lg         | 4       |
 * | xl         | 4       |
 * | 2xl        | 4       |
 * +------------+---------+
**/
.slider.rectangle-medium :global(.swiper-slide) {
  width: calc((var(--column-plus-gutter-width) * 5) - var(--gutter-width));
}
@screen md {
  .slider.rectangle-medium :global(.swiper-slide) {
    width: calc((var(--column-plus-gutter-width) * 8) - var(--gutter-width));
  }
}
@screen lg {
  .slider.rectangle-medium :global(.swiper-slide) {
    width: calc((var(--column-plus-gutter-width) * 4) - var(--gutter-width));
  }
}

/**
 * Rectangle (16x9) large size grid item. Typically used for live concert teasers.
 *
 * +------------+---------+
 * | Breakpoint | Columns |
 * +------------+---------+
 * | sm         | 5       |
 * | md         | 8       |
 * | lg         | 6       |
 * | xl         | 6       |
 * | 2xl        | 6       |
 * +------------+---------+
**/
.slider.rectangle-large :global(.swiper-slide) {
  width: calc((var(--column-plus-gutter-width) * 5) - var(--gutter-width));
}
@screen md {
  .slider.rectangle-large :global(.swiper-slide) {
    width: calc((var(--column-plus-gutter-width) * 8) - var(--gutter-width));
  }
}
@screen lg {
  .slider.rectangle-large :global(.swiper-slide) {
    width: calc((var(--column-plus-gutter-width) * 6) - var(--gutter-width));
  }
}
